.App {
  text-align: center;
}
.BarChart {
    height:500px;
    width: 500px;
  
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.box-shadow-md {
  box-shadow: 1px 2px 9px #0c0c0c00;
  margin: 3em;
  padding: 3em;
  border-radius: 40px;
}

.container {
  border: '10px';
  display: 'solid';
}

.background-red {
  background-color: rgba(40, 38, 151, 0.093);
}
.hero .overlay {
	height: 100%;
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
	width: 100%;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
